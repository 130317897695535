@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,200;0,300;0,400;0,500;1,200&display=swap');

* {
    font-family: 'Albert Sans', sans-serif;
}

/* CARDS */
.card-container {
    @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-10
}

.menu-card {
    @apply border border-slate-300 text-center hover:scale-105 
        transition cursor-pointer rounded-md bg-white drop-shadow hover:drop-shadow-lg
}

.menu-card img {
    @apply rounded-tl-md rounded-tr-md
}

/* BUTTONS */
.button {
    @apply px-4 py-2 text-center text-gray-100 focus:ring-4 font-semibold transition-all disabled:opacity-50 cursor-pointer rounded-md
}

.button-success {
    @apply bg-green-500 focus:ring-4 ring-green-300 hover:text-white hover:bg-green-400
}

.button-warning {
    @apply bg-yellow-500 focus:ring-4 ring-yellow-300 hover:text-white hover:bg-yellow-400
}

.button-danger {
    @apply bg-red-500 focus:ring-4 ring-red-300 hover:text-white hover:bg-red-400
}

.button-primary {
    @apply bg-blue-500 focus:ring-4 ring-blue-300 hover:text-white hover:bg-blue-400
}

/* FORMS */
.input-field {
    @apply w-full h-10 p-2 rounded-md border border-gray-300 
}

.select-field {
    @apply w-full h-10 pl-1 rounded-md border border-gray-300 bg-white
}

.whatsapp-icon {
    @apply fixed w-20 h-20 bg-gray-100 drop-shadow-lg z-40 right-5 bottom-5 rounded-full flex justify-center items-center hover:shadow-lg hover:shadow-green-500 cursor-pointer
}

@import '~@fortawesome/fontawesome-free/css/fontawesome';
@import '~@fortawesome/fontawesome-free/css/regular';
@import '~@fortawesome/fontawesome-free/css/solid';
@import '~@fortawesome/fontawesome-free/css/brands';